// src/utils.js
export const BASE_URL = "https://portalcap.unique.dev.br";
export const chaveServerApp = "3539431081732415498a7e54194a0b71de80e5e0";
export const keyDecode = "cG9ydGFsY2Fw";
export const keyDecodeStatus = "cG9ydGFsY2FwdmVuZGFzdGF0dXM";

export async function fetchPaymentStatus(token, url, hash, customId, keyDecodeStatus) {
  const data = {
    token: token,
    hash: hash,
    custom_id: customId,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Erro na requisição');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Erro ao buscar status do pagamento:', error);
    throw error;
  }
}
