import React, { useState } from 'react';
import Lottie from 'react-lottie';
import { useLocation } from 'react-router-dom';
import errorAnimation from './assets/error.json';
import './ErrorPage.css';

const ErrorPage = () => {
  const location = useLocation();
  const { errorMsg, valueInput } = location.state;

  // Separando o bilhete e o hash
  const bilhete = valueInput.slice(-12); // Últimos 12 dígitos do valueInput
  const receivedHash = valueInput.slice(0, -12); // O restante antes do bilhete

  // Estado para alternar entre DV = 1 e DV = 2
  const [isDv1, setIsDv1] = useState(true); // Inicia com DV = 1
  const [clickCount, setClickCount] = useState(0); // Contador de cliques
  const [showDetails, setShowDetails] = useState(false); // Estado para exibir ou ocultar os detalhes

  // Alterna entre DV = 1 e DV = 2
  const toggleDv = () => {
    setIsDv1(!isDv1); // Alterna o estado entre true (DV = 1) e false (DV = 2)
  };

  // Função para contar cliques no Lottie
  const handleLottieClick = () => {
    const newCount = clickCount + 1;
    setClickCount(newCount);

    // Se o número de cliques for 5 ou mais, exibe os detalhes
    if (newCount >= 5) {
      setShowDetails(true);
    }
  };

  let praca, edicao, titulo, dv, dvMessage;

  if (isDv1) {
    // Caso DV tenha 1 dígito
    praca = bilhete.slice(0, 2); // Primeiros 2 dígitos
    edicao = bilhete.slice(2, 5); // Próximos 3 dígitos
    titulo = bilhete.slice(5, 11); // Próximos 6 dígitos
    dv = bilhete.slice(11); // Último dígito (DV)
    dvMessage = "Formato DV = 1: Praça, Edição, Título, DV ->";
  } else {
    // Caso DV tenha 2 dígitos, a Praça tem 1 dígito
    praca = bilhete.slice(0, 1); // Primeiro 1 dígito (Praça)
    edicao = bilhete.slice(1, 4); // Próximos 3 dígitos (Edição)
    titulo = bilhete.slice(4, 10); // Próximos 6 dígitos (Título)
    dv = bilhete.slice(10, 12); // Últimos 2 dígitos (DV)
    dvMessage = "Formato DV = 2: Praça, Edição, Título, DV ->";
  }

  const defaultOptionsCheck = {
    loop: false,
    autoplay: true,
    animationData: errorAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleButtonClick = () => {
    window.location.href = `https://vendas.portalcap.com.br/`;
  };

  return (
    <div className="error-container">
      <div className="error-check" onClick={handleLottieClick}>
        <Lottie options={defaultOptionsCheck} height={150} width={150} />
      </div>
      <div className="error-message">{errorMsg}</div>
      <div className="error-description">Você pode também adquirir seu Bilhete através do nosso aplicativo ou venda on-line.</div>

      {/* Exibir as informações apenas se o usuário tiver clicado 5 vezes */}
      {showDetails && (
        <>
          <div className="bilhete-info">
            <strong>Bilhete:</strong> {bilhete}
          </div>
          <div className="bilhete-details">
            <p><strong>Praça:</strong> {praca}</p>
            <p><strong>Edição:</strong> {edicao}</p>
            <p><strong>Título:</strong> {titulo}</p>
            <p><strong>{dvMessage}</strong> {dv}</p> {/* Exibe a mensagem dependendo do tamanho do DV */}
          </div>
          <div className="hash-info">
            <strong>Hash:</strong> {receivedHash}
          </div>

          {/* Botão para alternar entre DV = 1 e DV = 2 */}
          <button className="toggle-button" onClick={toggleDv}>
            Alternar para {isDv1 ? "DV = 2" : "DV = 1"}
          </button>
        </>
      )}

      <button className="error-button" onClick={handleButtonClick}>Comprar On-line</button>
    </div>
  );
};

export default ErrorPage;
