import React from 'react';
import Lottie from 'react-lottie';
import { useLocation } from 'react-router-dom';
import checkAnimation from './assets/Animation_check.json';
import './SuccessPage.css';

const SuccessPage = () => {
  const location = useLocation();
  const { clientName, clientCPF } = location.state;

  const defaultOptionsCheck = {
    loop: false,
    autoplay: true,
    animationData: checkAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleButtonClick = () => {
    window.location.href = `https://vendas.portalcap.com.br/?cpf=${clientCPF}`;
  };

  return (
    <div className="success-container">
      <div className="success-check">
        <Lottie options={defaultOptionsCheck} height={150} width={150} />
      </div>
      <div className="success-message">Sucesso</div>
      <div className="success-message">Olá, {clientName}</div>
      <div className="success-description">Seu pagamento foi aprovado!</div>
      <div className="success-description">
        Para visualizar suas compras, dezenas e os prêmios de cada sorteio, clique no botão abaixo e selecione Consultar na próxima tela.
      </div>
      <div className="success-description attention">
        ATENÇÃO: A aquisição deste Bilhete implica contribuição à Entidade Beneficiada apresentada na tela principal do aplicativo e no anverso do Bilhete.
      </div>
      <button className="success-button" onClick={handleButtonClick}>Ver Bilhetes</button>
    </div>
  );
};

export default SuccessPage;
