import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, useNavigate } from 'react-router-dom';
import sha256 from 'js-sha256';
import Lottie from 'react-lottie';
import { FaCheck, FaUser, FaStore } from 'react-icons/fa'; // Import icons
import errorAnimation from './assets/error.json';
import pulseAnimation from './assets/Animation_pulse.json';
import waitAnimation from './assets/animation_wait.json';
import logo from './assets/logo.png'; // Certifique-se de que o caminho está correto
import './App.css';
import { fetchPaymentStatus } from './utils';
import SuccessPage from './SuccessPage';
import ErrorPage from './ErrorPage';

const BASE_URL = "https://portalcap.unique.dev.br";
const chaveServerApp = "3539431081732415498a7e54194a0b71de80e5e0";
const keyDecode = "cG9ydGFsY2Fw";
const keyDecodeStatus = "cG9ydGFsY2FwdmVuZGFzdGF0dXM";



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:value" element={<CaptureValue />} />
        <Route path="/" element={<Home />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/error" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}




function formatTitulo(titulo) {
  const tituloFormatado = titulo.padStart(6, '0');
  return `${tituloFormatado.slice(0, 3)}.${tituloFormatado.slice(3)}`;
}




function CaptureValue() {
  let { value } = useParams();
  const navigate = useNavigate();
  const [responseJson, setResponseJson] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [copied, setCopied] = useState(false);
  const [titulo, setTitulo] = useState('');
  const [timeLeft, setTimeLeft] = useState(300); // Inicializa com 5 minutos (300 segundos)
  const hasFetched = useRef(false);
  const intervalRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      if (value && !hasFetched.current) {
        const bilhete = value.slice(-12);
        const receivedHash = value.slice(0, -12);
        const generatedHash = sha256(bilhete + '.' + keyDecode);

        if (receivedHash === generatedHash) {
          setIsValid(true);
          const hash = generateRandomHash();
          const token = generateToken(hash, chaveServerApp);

          const verificador = 1;

          if (verificador === 1) {
            setTitulo(bilhete.substring(5, 11));
          } else {
            setTitulo(bilhete.substring(4, 10));
          }

          const data = {
            token: token,
            hash: hash,
            bilhete: bilhete,
          };

          hasFetched.current = true;

          try {
            const response = await fetch(`${BASE_URL}/api/${keyDecode}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            });

            const dataResponse = await response.json();

console.log(dataResponse);

            if (dataResponse.SERVER_ERROR) {
              setIsValid(false);
              setErrorMessage(dataResponse.SERVER_RETURN);
              if (dataResponse.SERVER_ERROR === 'VENDA_BLOQUEADA') {
                navigate('/error', { state: { errorMsg: 'Venda bloqueada. Por favor, entre em contato com o suporte.', valueInput: value } });
              } else {
                navigate('/error', { state: { errorMsg: dataResponse.SERVER_RETURN, valueInput: value } });
              }
            } else {
              setResponseJson(dataResponse);
              startStatusCheck(token, hash, dataResponse.custom_id, keyDecodeStatus);
              startTimeout(); // Start the timeout for redirection
              startCountdown(); // Inicia a contagem regressiva
            }
          } catch (error) {
            setErrorMessage('Ocorreu um erro ao processar sua solicitação. Tente novamente mais tarde.');
            console.error('Error:', error);
          }
        } else {
          setIsValid(false);
          setErrorMessage('Hash inválido. Por favor, verifique o valor informado.');
        }
      }
    };

    fetchData();

    const startStatusCheck = (token, hash, customId, keyDecodeStatus) => {
      intervalRef.current = setInterval(async () => {
        try {
          const statusResponse = await fetchPaymentStatus(token, `${BASE_URL}/api/${keyDecodeStatus}`, hash, customId, keyDecodeStatus);
          console.log('Status Response:', statusResponse);

          if (statusResponse.STATUS === 'PAGO') {
            clearInterval(intervalRef.current);
            clearTimeout(timeoutRef.current); // Clear the timeout if payment is completed
            navigate('/success', { state: { clientName: statusResponse.NOME_CLIENTE, clientCPF: statusResponse.CPF_CLIENTE } });
          }
        } catch (error) {
          console.error('Error fetching payment status:', error);
        }
      }, 7000);
    };

    const startTimeout = () => {
      timeoutRef.current = setTimeout(() => {
        clearInterval(intervalRef.current);
        window.location.href = 'https://vendas.portalcap.com.br';
      }, 300000); // 5 minutes timeout
    };

    const startCountdown = () => {
      const countdownInterval = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000); // Atualiza a cada segundo
    };

    return () => {
      clearInterval(intervalRef.current);
      clearTimeout(timeoutRef.current); // Clear the timeout on component unmount
    };
  }, [value, navigate]); // Adicionado navigate como dependência

  const copyToClipboard = () => {
    navigator.clipboard.writeText(responseJson ? responseJson.qrcode : '');
    setCopied(true);
    setTimeout(() => setCopied(false), 10000);
  };

  const defaultOptionsError = {
    loop: false,
    autoplay: true,
    animationData: errorAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const defaultOptionsPulse = {
    loop: true,
    autoplay: true,
    animationData: pulseAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const defaultOptionsWait = {
    loop: true,
    autoplay: true,
    animationData: waitAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div className="container">
      {isValid ? (
        <>
          <div className="content">
            <div className="header">
              <img src="/assets/logo.png" alt="Logo" className="logo" />
              <div className="text-pag-pix">Pague com PIX</div>
            </div>
            <div className="order-info">
              <h3>
                Ordem de compra gerada <br /> com sucesso!
              </h3>
              <p>
                <strong>Pedido:</strong> {responseJson ? responseJson.custom_id : 'Processando...'}
              </p>
              <p>
                <strong>Número do Bilhete:</strong> {formatTitulo(titulo)}
              </p>
              <h2>
                <strong>Valor do Bilhete:</strong> {responseJson ? 'R$ ' + responseJson.valor_pagar : 'Carregando...'}
              </h2>
            </div>
            <div className="status">
              <div className="txt-aguarde">
                <Lottie options={defaultOptionsPulse} height={50} width={50} />
                Aguardando pagamento...
              </div>
            </div>
            <div className="order-info">
              <h4>
                Você tem {formatTime(timeLeft)} minutos para <br />
                realizar o pagamento
              </h4>
            </div>
            <div className="qr-code">
              {responseJson && responseJson.imagemQrcode ? (
                <img src={responseJson.imagemQrcode} alt="QR Code" className="qr-image" />
              ) : (
                <Lottie options={defaultOptionsWait} height={120} width={120}/>
              )}
            </div>
            <div className="copy-code">
              <button className={`copy-button ${copied ? 'copied' : ''}`} onClick={copyToClipboard}>
                {copied ? (
                  <>
                    <FaCheck className="icon" /> Copiado com sucesso
                  </>
                ) : 'Copiar Código PIX'}
              </button>
            </div>
            <div className="instructions">
              <p>Para concluir o pagamento, você pode escanear o QR Code ou copiar o código acima e colar na seção PIX no internet banking do seu banco.</p>
            </div>

            <div className="distribuicaoTitle">
                <div className="infoDistribuidor">
                  Distribuidor
                </div> 

                <div className="infoPagador">
                  Ponto de Venda
                </div> 
           </div>
            <div className="distribuicao">
              <div className="infoDistribuidor">
                {responseJson ? <><FaUser className="icon" /> {responseJson.distribuidor_nome}</> : 'Carregando...'} 
              </div>
              <div className="infoPagador">
                {responseJson ? <><FaStore className="icon" /> {responseJson.pdv_nome}</> : 'Carregando...'}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="content error">
          <Lottie options={defaultOptionsError} height={150} width={150} />
          <h2>{errorMessage}</h2>
        </div>
      )}
    </div>
  );
}

function Home() {
  return (
    <div className="Home" style={styles.app}>
      <header className="Home-header" style={styles.header}>
        <img src={logo} className="Home-logo" alt="logo" style={styles.logo} />
        <h1 style={styles.welcome}>Para iniciar sua compra, escaneie o QR Code disponível no bilhete impresso.</h1>
      </header>
    </div>
  );
}

const styles = {
  app: {
    textAlign: 'center',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    height: '10vmin',
    pointerEvents: 'none',
  },
  welcome: {
    color: '#333',
    fontSize: '24px',
    marginTop: '20px',
    width: '90%', // Corrigido de 'with' para 'width'
    textAlign: 'center',
  },
};

function generateRandomHash() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

function generateToken(hash, chave) {
  return sha256(hash + chave);
}

export default App;
